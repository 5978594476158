body {
  margin: 0;
  font-family: "Zilla Slab", sans-serif !important;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100vw;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100%;
  max-width: 768px;
  height: 100%;

  margin: 0 auto;
}

.MuiTab-textColorSecondary {
  color: rgba(19, 194, 194, 0.6) !important;
}

.MuiTab-textColorSecondary.Mui-selected {
  color: #13c2c2 !important;
}

.MuiPickersModal-withAdditionalAction
  > .MuiButton-textPrimary
  > .MuiButton-label {
  color: #6b5c43;
}

.MuiTextField-root .MuiFormHelperText-root.Mui-error {
  color: #f5222d !important;
}
